import { useCallback, useMemo, useRef, useState } from 'react';
import debounce from 'react-utils/debounce';
import { useHttpClient } from '../../client/HttpClientContext';
import { Metrics } from '../../metrics/Metrics';
import { useDelayedState } from '../../utils/hooks/useDelayedState';
import { trackAutosaveSuccess, trackAutosaveUndo } from '../../utils/usageTrackingUtils';
import { savePropertyValues } from '../api';
import { useAutosavePackets } from './useAutosavePackets';
import { useAutosaveQueue } from './useAutosaveQueue';
import { useAutosaveWindowEvents } from './useAutosaveWindowEvents';
import { useHasAutosave } from './useHasAutosave';
import Raven from 'raven-js';
import uniqueId from 'transmute/uniqueId';
export const useAutosaveCore = () => {
  const httpClient = useHttpClient();
  const hasAutosave = useHasAutosave();
  const [status, setStatusWithDelay] = useDelayedState('idle');
  const [handleUndo, setHandleUndo] = useState(() => () => {});
  const handlersRef = useRef(new Map());
  const [lastSavedPacket, setLastSavedPacket] = useState(null);
  const {
    addItems,
    filter: filterQueue,
    enqueue,
    getItems,
    hasSourceId,
    removeItemBySourceId
  } = useAutosaveQueue();
  const {
    getPackets,
    pausePacketsForSource,
    addPacket,
    removePacket
  } = useAutosavePackets();
  useAutosaveWindowEvents(hasAutosave, getPackets);
  const save = useCallback(items => {
    const packetId = uniqueId('packet-');
    let saveCompleteTime = 0;
    setHandleUndo(() => () => {
      for (const item of items) {
        const {
          request,
          onUndo,
          onFailure
        } = item;
        const {
          previousProperties,
          objectTypeId
        } = request;

        // Track delay between save completed and undo started (in seconds)
        const duration = Date.now() - saveCompleteTime;
        const durationInSeconds = Math.floor(duration / 1000);
        trackAutosaveUndo({
          objectTypeId,
          propertyNames: previousProperties.map(({
            name
          }) => name)
        });
        savePropertyValues(Object.assign({}, request, {
          properties: previousProperties
        }), httpClient).catch(onFailure).then(updates => {
          Metrics.counter('autosave-undo-success', {
            duration: String(durationInSeconds)
          }).increment();
          if (updates) {
            onUndo(updates);
          }
        }).catch(e => {
          Metrics.counter('autosave-undo-failure').increment();
          Raven.captureException(new Error('Autosave undo handler failed'), {
            extra: {
              objectTypeId,
              propertyNames: previousProperties.map(({
                name
              }) => name),
              originalError: e
            }
          });
        });
      }
      setStatusWithDelay('reverted');
      setStatusWithDelay('idle', 3000);
    });
    const packet = {
      packetId,
      items
    };
    addPacket(packet);
    setStatusWithDelay('pending');
    Promise.allSettled(items.map(item => {
      const {
        request,
        onSuccess,
        onFailure
      } = item;
      const {
        properties,
        objectTypeId
      } = request;
      return savePropertyValues(request, httpClient).catch(onFailure).then(updates => {
        if (updates) {
          trackAutosaveSuccess({
            propertyNames: properties.map(({
              name
            }) => name),
            objectTypeId
          });
          Metrics.counter('autosave-success', {
            propertyCount: String(Object.keys(properties).length)
          }).increment();
          onSuccess(updates);
          return true;
        }
      }).catch(e => {
        Metrics.counter('autosave-failure').increment();
        Raven.captureException(new Error('Autosave success handler failed'), {
          extra: {
            objectTypeId,
            propertyNames: properties.map(({
              name
            }) => name),
            originalError: e
          }
        });
        return false;
      });
    })).then(results => {
      const allSuccess = results.every(result => result.status === 'fulfilled' && result.value);
      if (allSuccess) {
        setStatusWithDelay('success');
        setStatusWithDelay('idle', 10000);
        saveCompleteTime = Date.now();
      } else {
        setStatusWithDelay('idle');
      }
    }).catch(() => {}).finally(() => {
      setLastSavedPacket(packet);
      removePacket(packetId);
    });
  }, [addPacket, setStatusWithDelay, httpClient, removePacket]);
  const saveValidRequests = useCallback(() => {
    const handlers = Array.from(handlersRef.current.values());
    const itemsToProcess = getItems().filter(item => handlers.every(({
      getIsHandlerForRequest,
      getIsValidRequest
    }) => !getIsHandlerForRequest(item.request) || getIsValidRequest(item.request)));
    if (itemsToProcess.length > 0) {
      filterQueue(item => !itemsToProcess.includes(item));
      save(itemsToProcess);
    }
  }, [save, getItems, filterQueue, handlersRef]);
  const cancelRequestsForSource = useCallback(sourceId => {
    pausePacketsForSource(sourceId, (packet, index) => {
      addItems(packet.items);
      if (index === 0) {
        setStatusWithDelay('reverted');
        setStatusWithDelay('idle', 3000);
        Metrics.counter('autosave-cancelled-request').increment();
      }
    });
    removeItemBySourceId(sourceId);
  }, [pausePacketsForSource, setStatusWithDelay, addItems, removeItemBySourceId]);
  const enqueueRequest = useCallback(args => {
    enqueue(Object.assign({}, args, {
      timestamp: Date.now()
    }));
  }, [enqueue]);
  const registerHandler = useCallback(config => {
    handlersRef.current.set(config.id, config);
    return () => {
      handlersRef.current.delete(config.id);
    };
  }, []);
  const items = getItems();
  const requests = useMemo(() => items.map(item => item.request), [items]);
  return {
    cancelRequestsForSource,
    enqueueRequest,
    handleUndo,
    hasRequestForSource: hasSourceId,
    lastSavedPacket,
    packets: getPackets(),
    registerHandler,
    requests,
    saveValidRequests: useMemo(() => debounce(saveValidRequests, 25), [saveValidRequests]),
    status
  };
};