import { useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { CACHE_CRM_SEARCH_QUERY_PERMISSIONS, CACHE_CRM_SEARCH_QUERY_TOTAL_NUMBER_OF_OBJECTS, CACHE_INDIVIDUAL_OBJECTS_PERMISSIONS, SET_ERROR, SET_LOADING } from '../constants/actionTypes';
import { CacheWarmingQuery } from '../graphql/types';
import { useBulkActionsDispatch } from './useBulkActionsDispatch';
import { useBulkActionsSelectionState } from './useBulkActionsSelectionState';
import { useBulkActionsWarmupCacheForStateSlice } from './useBulkActionsWarmupCacheForStateSlice';

/**
 * Take the selection state input and cache warming state input, and then fetch data for it all
 */
export const useBulkActionsDataFetcher = () => {
  const dispatch = useBulkActionsDispatch();
  const warmupCacheForSlice = useBulkActionsWarmupCacheForStateSlice();
  const selectionState = useBulkActionsSelectionState();
  const objectTypeId = selectionState.selectionObjectTypeId;
  const selectionStateQuery = selectionState.selectionCrmSearchQuery;
  const warmupCacheForQuery = warmupCacheForSlice && warmupCacheForSlice.query;
  const warmupCacheForObjectIds = warmupCacheForSlice && warmupCacheForSlice.objectIds || [];
  const query = selectionStateQuery || warmupCacheForQuery;
  const selectionStateObjectIds = selectionState.selectionIds || [];
  const objectIds = Array.from(new Set([...selectionStateObjectIds.map(String), ...warmupCacheForObjectIds.map(String)])).sort();
  const skipSelectionViaQueryQueries = Boolean(!query);
  const skipSelectionViaIdsQuery = objectIds.length === 0;
  const queryVariables = useMemo(() => Object.assign({
    filterGroups: []
  }, query, {
    objectIds,
    objectTypeId,
    skipSelectionViaQueryQueries,
    skipSelectionViaIdsQuery
  }), [objectIds, query, objectTypeId, skipSelectionViaQueryQueries, skipSelectionViaIdsQuery]);
  const queryResult = useQuery(CacheWarmingQuery, {
    variables: queryVariables,
    skip: skipSelectionViaQueryQueries && skipSelectionViaIdsQuery
  });
  useEffect(() => {
    if (queryResult.data) {
      if (!skipSelectionViaQueryQueries && query) {
        //&& query explanation - typescript loses track that skipSelectionViaQueryQueries is equal to Boolean(query)
        const numberOfObjectsInQuery = queryResult.data.viewQuery.total;
        const numberOfObjectsInQueryThatAreEditable = queryResult.data.editQuery.total;
        const numberOfObjectsInQueryThatAreDeletable = queryResult.data.deleteQuery.total;
        const numberOfObjectsInQueryThatAreCommunicatable = queryResult.data.communicateQuery.total;
        dispatch({
          type: CACHE_CRM_SEARCH_QUERY_TOTAL_NUMBER_OF_OBJECTS,
          payload: [{
            query,
            totalNumberOfObjectsInQuery: numberOfObjectsInQuery
          }]
        });
        dispatch({
          type: CACHE_CRM_SEARCH_QUERY_PERMISSIONS,
          payload: [{
            query,
            permissions: {
              canView: true,
              canEdit: numberOfObjectsInQuery === numberOfObjectsInQueryThatAreEditable,
              // Only two objects can be merged at a time so we default to false for queries because
              // a user should never have a need to check the merge permissions on selections by query
              canMerge: false,
              canDelete: numberOfObjectsInQuery === numberOfObjectsInQueryThatAreDeletable,
              canCommunicate: numberOfObjectsInQuery === numberOfObjectsInQueryThatAreCommunicatable
            }
          }]
        });
      }
      if (queryResult.data.individualObjects) {
        dispatch({
          type: CACHE_INDIVIDUAL_OBJECTS_PERMISSIONS,
          payload: queryResult.data.individualObjects.map(individualObjectQueryResult => {
            return {
              objectTypeId,
              objectId: individualObjectQueryResult.id,
              permissions: {
                canEdit: individualObjectQueryResult.userPermissions.currentUserCanEdit,
                canMerge: individualObjectQueryResult.userPermissions.currentUserCanMerge,
                canView: individualObjectQueryResult.userPermissions.currentUserCanView,
                canDelete: individualObjectQueryResult.userPermissions.currentUserCanDelete,
                canCommunicate: individualObjectQueryResult.userPermissions.currentUserCanCommunicate
              }
            };
          })
        });
      }
    }
  }, [queryResult.data, dispatch, objectTypeId, query, skipSelectionViaQueryQueries]);
  useEffect(() => {
    dispatch({
      type: SET_LOADING,
      payload: {
        loadingKey: 'BULK_ACTIONS_INTERNAL_FETCHER',
        value: queryResult.loading
      }
    });
  }, [queryResult.loading, dispatch]);
  useEffect(() => {
    dispatch({
      type: SET_ERROR,
      payload: {
        errorKey: 'BULK_ACTIONS_INTERNAL_FETCHER',
        value: queryResult.error
      }
    });
  }, [queryResult.error, dispatch]);
};