import { useCallback, useRef, useState } from 'react';
/**
 * This is useful when you need asynchronous access to the latest value of a ref,
 * but also want your UI to re-render when the ref is updated.
 */
export const useStatefulRef = initialValue => {
  const [, setValue] = useState(initialValue);
  const ref = useRef(initialValue);
  const setRef = useCallback(updater => {
    // Type assertion used here to handle the case where the value we are storing is a function.
    // In this case, calling updater(ref.current) will behave oddly. React setState has some similar
    // issues around setState with functions being stored as state.
    //
    // You can still update the value correctly in this case, but in order to do so, you will need to
    // pass a wrapped function like so: `update((__currentValue) => () => undefined)`
    ref.current = typeof updater === 'function' ? updater(ref.current) : updater;
    setValue(ref.current);
  }, []);
  const getRef = useCallback(() => ref.current, []);
  return [getRef, setRef];
};