import PortalIdParser from 'PortalIdParser';
import Raven from 'raven-js';
import isObject from 'transmute/isObject';
import { makeIdentifierKey, parseIdentifierKey } from './AIPropertiesProcessingContextProvider';

// Metadata for properties that started processing over 24 hours ago is stale and will be ignored
const STALENESS_THRESHOLD = 1000 * 60 * 60 * 24;
function makePersistenceKey(objectTypeId) {
  return `smartProperties_${PortalIdParser.get()}_${objectTypeId}`;
}

/**
 * PersistedMetadata is of the format:
 * @example
 * {
 *   "<propertyName>": {
 *     "<objectId>": timestamp,
 *     "<objectId>": timestamp,
 *     ...
 *   },
 *   "<propertyName>": {
 *     "<objectId>": timestamp,
 *     "<objectId>": timestamp,
 *     ...
 *   },
 *   ...
 * }
 */

function parseRecentTimestamp(rawTimestamp) {
  if (typeof rawTimestamp !== 'number' && typeof rawTimestamp !== 'string') {
    return null;
  }
  const parsedTimestamp = typeof rawTimestamp !== 'number' ? parseInt(`${rawTimestamp}`, 10) : rawTimestamp;
  if (isNaN(parsedTimestamp)) {
    Raven.captureException(new Error('Invalid timestamp in local storage'), {
      extra: {
        rawTimestamp
      }
    });
    return null;
  }
  if (Date.now() - parsedTimestamp >= STALENESS_THRESHOLD) {
    return null;
  }
  return parsedTimestamp;
}
function deserializeMetadata(objectTypeId, rawPersistedMetadata) {
  if (rawPersistedMetadata === null || typeof rawPersistedMetadata !== 'string') {
    return {};
  }
  try {
    const persistedMetadata = JSON.parse(rawPersistedMetadata);
    if (persistedMetadata === null || !isObject(persistedMetadata)) {
      return {};
    }
    const parsedMetadata = {};
    for (const [propertyName, objectIdsToTimestamps] of Object.entries(persistedMetadata)) {
      if (objectIdsToTimestamps === null || !isObject(objectIdsToTimestamps)) {
        continue;
      }
      for (const [objectId, timestamp] of Object.entries(objectIdsToTimestamps)) {
        const startedAt = parseRecentTimestamp(timestamp);
        if (startedAt !== null) {
          parsedMetadata[makeIdentifierKey(objectTypeId, propertyName, objectId)] = {
            status: 'pending',
            isLoadingNewValue: false,
            startedAt
          };
        }
      }
    }
    return parsedMetadata;
  } catch (error) {
    return {};
  }
}
function serializeMetadata(metadata) {
  const serializedMetadataByObjectTypeId = Object.entries(metadata).reduce((acc, [key, value]) => {
    const {
      objectTypeId,
      propertyName,
      objectId
    } = parseIdentifierKey(key);

    // If we've tracked a key for an object type id, represent it in the
    // persisted metadata regardless of its status. This allows us to clear
    // the metadata for an object type id once it's no longer being processed.
    if (!Object.prototype.hasOwnProperty.call(acc, objectTypeId)) {
      acc[objectTypeId] = {};
    }
    if (value.status === 'pending') {
      if (!Object.prototype.hasOwnProperty.call(acc[objectTypeId], propertyName)) {
        acc[objectTypeId][propertyName] = {};
      }
      acc[objectTypeId][propertyName][objectId] = value.startedAt;
    }
    return acc;
  }, {});
  return Object.entries(serializedMetadataByObjectTypeId);
}
export function loadProcessingMetadata(objectTypeId) {
  try {
    const metadata = localStorage.getItem(makePersistenceKey(objectTypeId));
    return metadata ? deserializeMetadata(objectTypeId, metadata) : {};
  } catch (e) {
    Raven.captureException(new Error('Error retrieving metadata'), {
      extra: {
        e
      }
    });
    return {};
  }
}
export function persistProcessingMetadata(metadata) {
  try {
    const serializedMetadataEntries = serializeMetadata(metadata);
    for (const [objectTypeId, serializedMetadata] of serializedMetadataEntries) {
      const persistenceKey = makePersistenceKey(objectTypeId);
      if (Object.keys(serializedMetadata).length > 0) {
        localStorage.setItem(persistenceKey, JSON.stringify(serializedMetadata));
      } else {
        localStorage.removeItem(persistenceKey);
      }
    }
  } catch (e) {
    Raven.captureException(new Error('Error persisting metadata'), {
      extra: {
        e
      }
    });
  }
}
export const EXPORTED_FOR_TESTING = {
  STALENESS_THRESHOLD,
  parseRecentTimestamp,
  deserializeMetadata,
  serializeMetadata
};