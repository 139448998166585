import { useCallback } from 'react';
import { useStatefulRef } from '../../utils/hooks/useStatefulRef';
export const useAutosaveQueue = () => {
  const [getQueue, setQueue] = useStatefulRef(new Map());
  const setItemIfNewer = useCallback((queue, item) => {
    const existingItem = queue.get(item.request.sourceId);
    if (!existingItem || item.timestamp > existingItem.timestamp) {
      queue.set(item.request.sourceId, item);
    }
  }, []);
  const enqueue = useCallback(newItem => setQueue(queue => {
    const newQueue = new Map(queue);
    setItemIfNewer(newQueue, newItem);
    return newQueue;
  }), [setItemIfNewer, setQueue]);
  const filter = useCallback(predicate => {
    setQueue(queue => new Map([...queue].filter(([, item]) => predicate(item))));
  }, [setQueue]);
  const addItems = useCallback(items => {
    setQueue(queue => {
      const newQueue = new Map(queue);
      items.forEach(item => {
        setItemIfNewer(newQueue, item);
      });
      return newQueue;
    });
  }, [setItemIfNewer, setQueue]);
  const removeItemBySourceId = useCallback(sourceId => {
    setQueue(queue => {
      const newQueue = new Map(queue);
      newQueue.delete(sourceId);
      return newQueue;
    });
  }, [setQueue]);
  const getItems = useCallback(() => Array.from(getQueue().values()), [getQueue]);
  const getItemBySourceId = useCallback(sourceId => getQueue().get(sourceId), [getQueue]);
  const hasSourceId = useCallback(sourceId => getQueue().has(sourceId), [getQueue]);
  const clear = useCallback(() => setQueue(new Map()), [setQueue]);
  const size = useCallback(() => getQueue().size, [getQueue]);
  return {
    addItems,
    clear,
    enqueue,
    getItems,
    getItemBySourceId,
    hasSourceId,
    filter,
    removeItemBySourceId,
    size
  };
};