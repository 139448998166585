const MAX_AI_VIEWS_PER_OBJECT_TYPE = 100;
export const getAiViewDataKey = objectTypeId => `aiViewData_${objectTypeId}`;
export const getStoredAiViewData = (objectTypeId, viewId) => {
  try {
    //if viewId is not provided, return all views
    const data = localStorage.getItem(getAiViewDataKey(objectTypeId));
    const views = data ? JSON.parse(data) : {};
    //if viewId is provided, return the view with the given viewId
    if (viewId) {
      return views[viewId] || null;
    }
    return views;
  } catch (err) {
    console.error('Error reading AI view data from localStorage', err);
    return null;
  }
};
export const storeAiViewData = (objectTypeId, viewId, viewData) => {
  try {
    const views = getStoredAiViewData(objectTypeId) || {};
    const now = new Date().toISOString();

    // If this view already exists, just update it
    if (views[viewId]) {
      views[viewId] = Object.assign({}, viewData, {
        viewId,
        createdAt: views[viewId].createdAt,
        // Preserve original creation timestamp
        updatedAt: now // Update the updatedAt timestamp
      });
    } else {
      // If adding a new view would exceed the limit, remove oldest views
      if (Object.keys(views).length >= MAX_AI_VIEWS_PER_OBJECT_TYPE) {
        const viewEntries = Object.entries(views);
        viewEntries.sort(([, a], [, b]) => {
          const dateA = a.updatedAt ? new Date(a.updatedAt).getTime() : 0;
          const dateB = b.updatedAt ? new Date(b.updatedAt).getTime() : 0;
          return dateA - dateB;
        });

        // Remove oldest updated view
        const [oldestViewId] = viewEntries[0];
        delete views[oldestViewId];
      }

      // Add the new view with viewId, creation and update timestamps
      views[viewId] = Object.assign({}, viewData, {
        viewId,
        createdAt: now,
        updatedAt: now
      });
    }
    localStorage.setItem(getAiViewDataKey(objectTypeId), JSON.stringify(views));
    return true;
  } catch (error) {
    console.error('Error storing AI view data in localStorage:', error);
    return false;
  }
};