import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useStableFunction } from '../utils/hooks/useStableFunction';
import { makeIdentifierKey } from './AIPropertiesProcessingContextProvider';
function pollListenersReducer(pollListeners, action) {
  if (action.type === 'start') {
    const newState = structuredClone(pollListeners);
    const {
      objectTypeId,
      propertyName,
      objectId
    } = action;
    const key = makeIdentifierKey(objectTypeId, propertyName, objectId);
    if (Object.prototype.hasOwnProperty.call(newState, key)) {
      newState[key].add(action.id);
    } else {
      newState[key] = new Set([action.id]);
    }
    return newState;
  } else if (action.type === 'detach') {
    const newState = structuredClone(pollListeners);
    const {
      objectTypeId,
      propertyName,
      objectId
    } = action;
    const key = makeIdentifierKey(objectTypeId, propertyName, objectId);
    if (Object.prototype.hasOwnProperty.call(newState, key)) {
      newState[key].delete(action.id);
      if (newState[key].size === 0) {
        delete newState[key];
      }
    }
    return newState;
  }
  return pollListeners;
}
export function usePollListeners(fetcher, {
  pollInterval = 3000
} = {}) {
  // Store a map of keys to poll to ids of components that are listening to them
  const [pollListeners, pollDispatch] = useReducer(pollListenersReducer, {});
  const [isPolling, setIsPolling] = useState(false);
  const pollingRef = useRef(null);
  const poll = useStableFunction(() => {
    fetcher(Object.keys(pollListeners));
  });
  useEffect(() => {
    if (Object.keys(pollListeners).length > 0) {
      setIsPolling(true);
    } else {
      setIsPolling(false);
    }
  }, [pollListeners]);
  useEffect(() => {
    if (isPolling && pollingRef.current === null) {
      pollingRef.current = setInterval(poll, pollInterval);
    } else if (!isPolling && pollingRef.current !== null) {
      clearInterval(pollingRef.current);
      pollingRef.current = null;
    }
    return () => {
      if (pollingRef.current !== null) {
        clearInterval(pollingRef.current);
        pollingRef.current = null;
      }
    };
  }, [isPolling, poll, pollInterval]);
  const startPollingAndAttach = useCallback((id, objectTypeId, propertyName, objectId) => {
    pollDispatch({
      type: 'start',
      id,
      objectTypeId,
      propertyName,
      objectId
    });
  }, []);
  const detachPolling = useCallback((id, objectTypeId, propertyName, objectId) => {
    pollDispatch({
      type: 'detach',
      id,
      objectTypeId,
      propertyName,
      objectId
    });
  }, []);
  return {
    startPollingAndAttach,
    detachPolling
  };
}