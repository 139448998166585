import { useCallback } from 'react';
import { useStatefulRef } from '../../utils/hooks/useStatefulRef';
export const useAutosavePackets = () => {
  const [getPackets, setPackets] = useStatefulRef([]);
  const addPacket = useCallback(packet => setPackets(ps => [packet, ...ps]), [setPackets]);
  const removePacket = useCallback(id => {
    const packet = getPackets().find(p => p.packetId === id);
    if (packet) {
      setPackets(ps => ps.filter(p => p.packetId !== id));
    }
  }, [getPackets, setPackets]);
  const pausePacketsForSource = useCallback((sourceId, onPause) => {
    getPackets().forEach((packet, index) => {
      if (packet.items.some(item => item.request.sourceId === sourceId)) {
        onPause(packet, index);
        removePacket(packet.packetId);
      }
    });
  }, [getPackets, removePacket]);
  return {
    addPacket,
    getPackets,
    pausePacketsForSource,
    removePacket
  };
};