import { useCallback, useRef } from 'react';
const createPropertyKey = identifier => `${identifier.propertyName}-${identifier.objectTypeId}-${identifier.objectId}`;
export const useAutosaveEditingState = () => {
  const isEditingRef = useRef(new Set());
  const getIsEditing = useCallback(identifier => isEditingRef.current.has(createPropertyKey(identifier)), []);
  const setIsEditing = useCallback(({
    propertyName,
    objectTypeId,
    objectId,
    isEditing
  }) => {
    const identifier = {
      propertyName,
      objectTypeId,
      objectId
    };
    if (isEditing) {
      isEditingRef.current.add(createPropertyKey(identifier));
    } else {
      isEditingRef.current.delete(createPropertyKey(identifier));
    }
  }, []);
  return {
    getIsEditing,
    setIsEditing
  };
};