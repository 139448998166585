import { useEffect, useRef, useState } from 'react';
import { useIsMounted } from '../../v2/hooks/useIsMounted';
export const useAsyncFunction = (fn, options = {
  skip: false
}) => {
  const [state, setState] = useState({
    loading: !options.skip,
    data: undefined,
    error: undefined
  });
  const isMounted = useIsMounted();
  const lastCallId = useRef(0);
  useEffect(() => {
    if (options.skip) {
      // Minor optimization: React does not shallowly compare state values on change, so even if
      // loading/error/data are the same as the previous state, setting them here will cause a rerender.
      // The specific problematic case is when `skip` is true — we already set up the initial state value
      // correctly, but were setting it again to the same values in this effect. This check ensures that
      // we only trigger a rerender if the values are different from what we want in a "skipped" state
      // (i.e if `skip` was set to true after mount).
      setState(currentState => {
        if (currentState.loading !== false || currentState.data !== undefined || currentState.error !== undefined) {
          return {
            loading: false,
            data: undefined,
            error: undefined
          };
        }
        return currentState;
      });
      return;
    }
    setState({
      loading: true,
      data: undefined,
      error: undefined
    });
    const callId = ++lastCallId.current;
    fn().then(data => {
      if (isMounted.current && callId === lastCallId.current) {
        setState({
          loading: false,
          data,
          error: undefined
        });
      }
    }).catch(error => {
      if (isMounted.current && callId === lastCallId.current) {
        setState({
          loading: false,
          data: undefined,
          error
        });
      }
    });
  }, [fn, isMounted, options.skip]);
  return state;
};