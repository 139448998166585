import { useContext } from 'react';
import { createRpcClientV2 } from 'rpc-client-utils';
import memoize from 'transmute/memoize';
import { HttpClientContext } from './HttpClientContext';
const getRpcClient = memoize(hubHttpClient => createRpcClientV2({
  hubHttpClient
}));
export function useRpcClient() {
  const hubHttpClient = useContext(HttpClientContext);
  return getRpcClient(hubHttpClient);
}