import { useCallback, useEffect } from 'react';
import { Metrics } from 'customer-data-objects/metrics/Metrics';
export const useAutosaveWindowEvents = (hasAutosave, getPackets) => {
  const handleBeforeUnload = useCallback(e => {
    if (hasAutosave && getPackets().length > 0) {
      Metrics.counter('autosave-saving-and-beforeunload').increment();
      e.preventDefault();
      e.returnValue = true;
    }
  }, [getPackets, hasAutosave]);
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);
};